import React, { useState, useEffect, useRef } from 'react';

import { ExternalLink } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import Swipe from 'react-easy-swipe';
import css from './FeaturedIn.module.css';
/*assets */
import article0 from '../../../../assets/articles/article1.webp';
import article1 from '../../../../assets/articles/article2.webp';
import article2 from '../../../../assets/articles/article3.webp';
import article3 from '../../../../assets/articles/article4.webp';
import article4 from '../../../../assets/articles/article5.webp';
import article5 from '../../../../assets/articles/article6.webp';
import article6 from '../../../../assets/articles/article7.webp';
import article7 from '../../../../assets/articles/article8.webp';
import article8 from '../../../../assets/articles/article9.webp';
import article9 from '../../../../assets/articles/article10.webp';
import article10 from '../../../../assets/articles/art11.webp';
import article11 from '../../../../assets/articles/article12.webp';
import article15 from '../../../../assets/articles/article16.webp';
import article16 from '../../../../assets/articles/article17.webp';

const data = [
  {
    name: 'zdf',
    url: 'https://www.zdf.de/gesellschaft/plan-b/plan-b-da-geht-was-deutschland-108.html',
    src: article0,
    link: '',
  },
  {
    name: 'business-punk',
    url:
      'https://www.business-punk.com/2021/07/reisen-mit-sozialem-nachhaltigem-aspekt-socialbnb-macht-es-moeglich/',
    src: article1,
    link: '',
  },
  {
    name: 'galileo',
    url:
      'https://www.galileo.tv/abenteuer/socialbnb-charity-urlaub-soziale-projekte-unterstuetzen/',
    src: article2,
    link: '',
  },
  {
    name: 'GEO SAISON',
    url: '',
    src: article3,
    link: 'link-inactive',
  },
  {
    name: 'n-tv',
    url:
      'https://www.n-tv.de/mediathek/magazine/startup_news/Socialbnb-vereint-Tourismus-und-globales-Engagement-article23603817.html',
    src: article5,
    link: '',
  },
  {
    name: 'unwto',
    url: 'https://www.unwto.org/unwto-awake-tourism-challenge',
    src: article16,
    link: '',
  },
];

const FeaturedIn = () => {
  /* const [index, setIndex] = useState(0);
  const [autoplay, setAutoplay] = useState(true);
  const [sliderDirectionLeft, setSliderDirectionLeft] = useState(true); //
  const [localCoords, setLocalCoords] = useState({ x: 0, y: 0 });
  const [cursorDirection, setCursorDirection] = useState('center'); */

  /* track mouse position*/
  /*locally */
  /* const handleMouseMove = event => {
    // 👇️ Get the mouse position relative to the element
    if (typeof window !== 'undefined'){
    setLocalCoords({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
    let offsetRight = window.innerWidth - event.target.offsetLeft;
    if (event.clientX > 0 && event.clientX < 50) {
      setCursorDirection('left');
    } else if (localCoords.x > offsetRight - 100) {
      setCursorDirection('right');
    } else setCursorDirection('center');
  }; */

  /* const handleClick = event => {
    if (cursorDirection === 'left') {
      setAutoplay(false);
      setIndex(mod(index + 1, data.length));
      setSliderDirectionLeft(true);
      setAutoplay(true);
    } else if (cursorDirection === 'right') {
      setAutoplay(false);
      setIndex(mod(index - 1, data.length));
      setSliderDirectionLeft(false);
      setAutoplay(true);
    }
  }; */
  //const AUTOPLAY_INTERVAL = 4000;
  // 👇️ autoplay of slider
  /* useEffect(() => {
    let requestId;

    const animateSlider = timestamp => {
      const elapsed = timestamp - lastTimestampRef.current;

      if (elapsed > AUTOPLAY_INTERVAL) {
        lastTimestampRef.current = timestamp;

        setIndex(prevIndex => mod(prevIndex + (sliderDirectionLeft ? 1 : -1), data.length));
      }

      requestId = requestAnimationFrame(animateSlider);
    };

    const lastTimestampRef = { current: performance.now() };

    if (autoplay) {
      requestId = requestAnimationFrame(animateSlider);
    }
    return () => cancelAnimationFrame(requestId);
  }, [autoplay, sliderDirectionLeft]); */

  //swipe logic

  /* const handleSwipeLeft = () => {
    setAutoplay(false);
    console.log('swiped left');
    setIndex((index + 1) % data.length);

    setSliderDirectionLeft(true);
    setAutoplay(true);
  };

  const handleSwipeRight = () => {
    setAutoplay(false);
    console.log('swiped right');
    setIndex(mod(index - 1, data.length));

    setSliderDirectionLeft(false);
    setAutoplay(true);
  }; */

  //modular f(x) , returns positive value
  /* const mod = (n, m) => {
    let result = n % m;

    return result >= 0 ? result : result + m;
  }; */

  const cards = data.map((slide, i) => {
    //const indexLeft = mod(index - 1, data.length);
    //const indexRight = mod(index + 1, data.length);

    /* let className = '';
    if (i === index) {
      className = `${css['cards']} ${css['card-0']}`;
    } else if (i === indexRight) {
      className = `${css['cards']} ${css['card-1']}`;
    } else if (i === (indexRight + 1) % data.length) {
      className = `${css['cards']} ${css['card-2']}`;
    } else if (i === (indexRight + 2) % data.length) {
      className = `${css['cards']} ${css['card-3']} `;
    } else if (i === (indexRight + 3) % data.length) {
      className = `${css['cards']} ${css['card-4']} `;
    } else if (i === (indexRight + 4) % data.length) {
      className = `${css['cards']} ${css['card-5']}`;
    } else if (i === (indexRight + 5) % data.length) {
      className = `${css['cards']} ${css['card-6']}`;
    } else if (i === (indexRight + 6) % data.length) {
      className = `${css['cards']} ${css['card-7']}`;
    } else if (i === (indexRight + 7) % data.length) {
      className = `${css['cards']} ${css['card-8']}`;
    } else if (i === (indexRight + 8) % data.length) {
      className = `${css['cards']} ${css['card-9']}`;
    } else if (i === (indexRight + 9) % data.length) {
      className = `${css['cards']} ${css['card-10']}`;
    } else if (i === (indexRight + 10) % data.length) {
      className = `${css['cards']} ${css['card-11']}`;
    } else if (i === (indexRight + 11) % data.length) {
      className = `${css['cards']} ${css['card-12']}`;
    } else if (i === (indexRight + 12) % data.length) {
      className = `${css['cards']} ${css['card-13']}`;
    } else {
      className = `${css['cards']} ${css['hidden']}`;
    } */
    return (
      <div key={i} className={css.cards}>
        <div
          key={i}
          className={css['center-img']}
          data-elbaction={
            'click:click lp-feat-' + [`${slide.name}`]
          }
        >
          <ExternalLink className={`${css['links']} ${css[slide.link]}`} href={slide.url}>
            <img className={css['cards-img']} src={slide.src} alt={slide.name} />
          </ExternalLink>
        </div>
      </div>
    );
  });
  return (
    <section className={css['cards-container']}>
      <h2 className={css['section-title']}>
        <FormattedMessage id="landing.featured" />
      </h2>
      <div>{cards}</div>
    </section>
  );
  /*<Swipe
      onSwipeLeft={handleSwipeLeft}
      onSwipeRight={handleSwipeRight}
      allowMouseEvents={true}
      tolerance={50}
      delta={10}
      preventdefaulttouchmoveevent="false"
      className={css[`${cursorDirection}`]}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >


    </Swipe> */
};

export default FeaturedIn;
